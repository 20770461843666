import React from 'react';

import { ProjectsItems } from 'src/constant';
import { colors } from 'src/styles';

import { Text } from '../Text';
import { Project } from '../Project';

import * as S from './styled';

export type OrganizationProjectsProps = ProjectsItems;

export const OrganizationProjects: React.FC<OrganizationProjectsProps> = ({
  name,
  position,
  startAt,
  finishAt,
  projects,
}) => {
  return (
    <S.OrganizationProjectContainer>
      <S.OrganizationInfoWrapper>
        <S.OrganizationInfoContainer>
          <div>
            <S.OrganizationName size={3.2} weight={700}>
              {name}
            </S.OrganizationName>
            <Text size={1.6} color={colors.textSecondary} style={{ marginBottom: '0.2rem' }}>
              {position}
            </Text>
          </div>
          <Text size={1.6} color={colors.textSecondary}>
            {startAt} ~ {finishAt}
          </Text>
        </S.OrganizationInfoContainer>
      </S.OrganizationInfoWrapper>
      <S.OrganizationRowContainer>
        {projects.map((props, i) => (
          <Project key={i} {...props} />
        ))}
      </S.OrganizationRowContainer>
    </S.OrganizationProjectContainer>
  );
};
