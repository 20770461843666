import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProjectDetails } from 'src/constant';
import { colors } from 'src/styles';
import { useImageViewer } from 'src/providers';

import { Text } from '../Text';

import * as S from './styled';

export type ProjectProps = ProjectDetails;

const IMG_MAX_RENDER_AMOUNT = 4;

export const Project: React.FC<ProjectProps> = ({
  name,
  description,
  startAt,
  finishAt,
  links = [],
  skills = [],
  results = [],
  images = [],
}) => {
  const { showImage } = useImageViewer();

  return (
    <S.ProjectContainer>
      <S.ProjectTitleSection>
        <div>
          <S.ProjectNameColumn>
            <S.ProjectName>{name}</S.ProjectName>
            <S.ProjectIconList>
              {links.map(({ icon, link }, i) => {
                return (
                  <a key={i} href={link} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={icon} style={{ fontSize: '3.2rem' }} />
                  </a>
                );
              })}
            </S.ProjectIconList>
          </S.ProjectNameColumn>
          <Text color={colors.textSecondary}>{description}</Text>
        </div>
        {startAt && (
          <Text color={colors.textSecondary}>
            {startAt} ~ {finishAt}
          </Text>
        )}
      </S.ProjectTitleSection>
      {skills.length > 0 && (
        <div>
          <S.ProjectSectionTitle>사용 기술.</S.ProjectSectionTitle>
          <p>{skills.join(', ')}</p>
        </div>
      )}
      {results.length > 0 && (
        <div>
          <S.ProjectSectionTitle>이런 걸 했어요.</S.ProjectSectionTitle>
          <S.ProjectResultList>
            {results.map((result, i) => (
              <li key={i}>{result}</li>
            ))}
          </S.ProjectResultList>
        </div>
      )}
      <S.ProjectImagesRow>
        {images.slice(0, IMG_MAX_RENDER_AMOUNT).map((image, i, arr) => {
          const isLastItem = i === arr.length - 1;
          const isHaveMoreImages = images.length > IMG_MAX_RENDER_AMOUNT;
          const leftAmount = images.length - IMG_MAX_RENDER_AMOUNT;
          const isLastAndHasMoreImages = isHaveMoreImages && i == IMG_MAX_RENDER_AMOUNT - 1;

          return (
            <S.ProjectImageWrapper
              key={i}
              onClick={() => {
                showImage(images, i);
              }}
            >
              <S.ProjectImage src={image} isLastAndHasMoreImage={isLastAndHasMoreImages} />
              {isLastItem && isHaveMoreImages && (
                <S.ProjectImageLeftAmount>+{leftAmount}</S.ProjectImageLeftAmount>
              )}
            </S.ProjectImageWrapper>
          );
        })}
      </S.ProjectImagesRow>
    </S.ProjectContainer>
  );
};
