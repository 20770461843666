import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faClose } from '@fortawesome/free-solid-svg-icons';

import * as S from './styled';

export interface ImageViewerProps {
  images: string[];
  currentIndex: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  onClose: () => void;
}

export const ImageViewer: React.FC<ImageViewerProps> = ({
  images,
  currentIndex,
  onNextPage,
  onPrevPage,
  onClose,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <S.ImageViewerContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.08 }}
    >
      <S.ImageViewDummyContainer onClick={onClose} />
      <S.IconButton onClick={onPrevPage} style={{ opacity: currentIndex > 0 ? 1 : 0 }}>
        <FontAwesomeIcon icon={faAngleLeft} color="black" />
      </S.IconButton>
      {images.map((image, index) => (
        <S.Image
          src={image}
          key={index}
          style={{ display: index === currentIndex ? 'block' : 'none' }}
        />
      ))}
      <S.IconButton
        onClick={onNextPage}
        style={{ opacity: currentIndex < images.length - 1 ? 1 : 0 }}
      >
        <FontAwesomeIcon icon={faAngleRight} color="black" />
      </S.IconButton>
      <S.CloseButton onClick={onClose}>
        <FontAwesomeIcon icon={faClose} color="white" />
      </S.CloseButton>
    </S.ImageViewerContainer>
  );
};
