import { css } from '@emotion/react';

import { colors } from './colors';

export const globalStyle = css`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    color: ${colors.textPrimary};
  }

  body {
    margin: 0;
  }

  #___gatsby {
    max-width: 750px;
    margin: 0 auto;
  }

  html,
  body,
  #___gatsby {
    width: 100%;
    background-color: white;
  }

  #___gatsby {
    font-size: 1.8rem;
    font-weight: 400;
    font-family:
      'Pretendard Variable',
      Pretendard,
      -apple-system,
      BlinkMacSystemFont,
      system-ui,
      Roboto,
      'Helvetica Neue',
      'Segoe UI',
      'Apple SD Gothic Neo',
      'Noto Sans KR',
      'Malgun Gothic',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      sans-serif;
    letter-spacing: -0.03em;
  }

  p {
    margin: 0;
  }

  ::selection {
    background-color: ${colors.primary};
    color: white;
  }

  @media screen and (max-width: 768px) {
    html {
      font-size: 8px;
      padding: 0 4rem;
    }
  }

  @media screen and (max-width: 576px) {
    html {
      padding: 0 1.2rem;
    }
  }
`;
