export const colors = {
  white: '#ffffff',
  primary: '#395dff',
  textPrimary: '#262626',
  textSecondary: '#696973',
  imageBorder: '#f0eeee',
  noPage: {
    primary: '#8A6534',
    background: '#FFF4DB',
    textColor: '#232129',
  },
};
